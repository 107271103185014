// ** Initial State
const initialState = {
  allGroups: [],
  total: 1,
  params: {},
  myCustomPaginatin: "",
  selectedPlan:
    localStorage.getItem("selectedPlan") === "undefined"
      ? null
      : JSON.parse(localStorage.getItem("selectedPlan")),
  loading: false,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: "",
  entries_per_page: "",
  total_results: "",
  updatePagination: 0,
};

// console.log('sssssssssssss', selectedUser)

const contactGroup = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DATA":
      // console.log(action.data)
      return {
        ...state,
        allGroups: action.data,
        myCustomPaginatin: action.total,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };
    case "GET_PLAN":
      return { ...state, selectedPlan: action.data };
    case "DELETE_PLAN":
      return { ...state, updatePagination: action.data };
    case "START_LOADING":
      return { ...state, loading: true };
    case "END_LOADING":
      return { ...state, loading: false };
    case "PAGE_CHANGE":
      return { ...state, allGroups: [] };
    case "OPEN_MODEL":
      return { ...state, openModel: true };
    case "CLOSE_MODEL":
      return { ...state, openModel: false };
    default:
      return { ...state };
  }
};
export default contactGroup;
